<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="select_head">
      <div class="select">
        <el-input placeholder="请输入手机号" v-model="phone"></el-input>
        <div class="block">
          <el-date-picker
            v-model="selectDate"
            align="right"
            type="date"
            placeholder="选择日期"
            :clearable="false"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>

        <div style="display: flex; align-items: center">
          <el-button type="primary" @click="searchList">查询</el-button>
          <el-button type="info" @click="reset" style="margin-right: 40px"
            >重置</el-button
          >

          <el-switch
            v-if="isUserAuto"
            style="display: block"
            v-model="isAuto"
            active-text="自动提现"
            inactive-text="非自动提现"
            @change="handleAuto"
          >
          </el-switch>
        </div>
      </div>
      <div style="margin-right: 20px; color: red">
        {{ selectDate }} 待提现总金额：{{ AllMoney }}
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cashTime" label="提现日期" width="180">
        </el-table-column>
        <el-table-column
          prop="realname"
          label="真实姓名"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="phoneArea" label="区号"> </el-table-column>
        <el-table-column prop="phone" label="手机号" width="130">
        </el-table-column>
        <el-table-column prop="fromType" label="提现类型" width="130">
        </el-table-column>
        <el-table-column prop="cashMethod" label="提现方式" align="center">
        </el-table-column>
        <el-table-column prop="cashAccount" label="提现账号" width="200">
        </el-table-column>
        <el-table-column prop="bananceBef" label="提现前余额" width="120">
          <template slot-scope="scope"> $ {{ scope.row.bananceBef }} </template>
        </el-table-column>
        <el-table-column prop="received" label="提现到账金额" width="120">
          <template slot-scope="scope"> $ {{ scope.row.received }} </template>
        </el-table-column>
        <el-table-column prop="convertRmb" label="折合人民币" width="120">
          <template slot-scope="scope"> ¥ {{ scope.row.convertRmb }} </template>
        </el-table-column>
        <el-table-column prop="commission" label="提现手续费" width="120">
          <template slot-scope="scope"> $ {{ scope.row.commission }} </template>
        </el-table-column>
        <el-table-column prop="bananceAft" label="提现后用户金额" width="120">
          <template slot-scope="scope"> $ {{ scope.row.bananceAft }} </template>
        </el-table-column>
        <el-table-column prop="sucUser" label="成单用户" width="120">
        </el-table-column>
        <el-table-column prop="cpleCurrent" label="当前CPLE" width="130">
        </el-table-column>
        <el-table-column
          prop="triptocpleAdd"
          label="累计行程获取CPLE"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="receivedAdd" label="累计提现金额" width="110">
          <template slot-scope="scope">
            $ {{ scope.row.receivedAdd }}
          </template>
        </el-table-column>
        <el-table-column prop="count" label="累计提现次数" width="110">
        </el-table-column>
        <el-table-column prop="status" label="提现状态"> </el-table-column>
        <el-table-column
          prop="resultMsg"
          label="转账回馈"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <div class="operation" v-if="scope.row.status == '提现成功'">
              已提现
            </div>
            <div class="operation" v-if="scope.row.status == '提现中'">
              提现中
            </div>
            <div class="operation" v-else-if="scope.row.status == '待提现'">
              <el-button
                @click="handleClick(scope.row, 1)"
                type="text"
                size="small"
                >同意提现</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleClick(scope.row, 2)"
                >拒绝提现</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleClick(scope.row, 3)"
                style="background: rgb(115 174 235); color: #fff"
                >人工通道</el-button
              >
            </div>
            <div class="operation" v-else-if="scope.row.status == '提现失败'">
              失败
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <div>
          真实姓名：<span style="color: cadetblue">{{
            this.msg.realName
          }}</span>
        </div>
        <br />
        <br />
        <div>
          提现账号：<span style="color: cadetblue">{{ this.msg.phone }}</span>
        </div>
        <br />
        <br />
        <div v-if="msg.show">
          USDT数量：<span style="color: cadetblue">{{ this.msg.count }}</span>
        </div>
        <div v-else-if="!msg.show">
          折合人民币：<span style="color: cadetblue">{{ this.msg.money }}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            v-if="msg.show"
            v-clipboard:copy="
              msg.phone + ' # ' + msg.count + ' # ' + msg.realName
            "
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >复制</el-button
          >
          <el-button
            v-else-if="!msg.show"
            v-clipboard:copy="
              msg.phone + ' # ' + msg.money + ' # ' + msg.realName
            "
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >复制</el-button
          >
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleClickTrue">确 定</el-button>
        </span>
      </el-dialog>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import "./index.scss";
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      phone: "", // input手机号
      selectDate: "", // 时间选择器
      tableData: [],
      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "现金提现",
        },
        {
          name: "现金提现列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      dialogVisible: false,
      msg: {
        realName: "",
        phone: "",
        money: "",
        count: "",
        show: false,
      },

      AllMoney: "", // 总金额

      isUserAuto: true,
      isAuto: true,
    };
  },

  mounted() {
    if (localStorage.userName == "13436969806") {
      this.isUserAuto = true;
    } else if (localStorage.userName !== "13436969806") {
      this.isUserAuto = false;
    }

    showLoading();
    const opt = {
      url: reqUrl.getWalletList,
      method: "POST",
      params: JSON.stringify({
        cashTime: "",
        nonce: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        sign: "",
        status: "",
        token: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        this.tableData = res.data.data.list;
        this.pageLength = res.data.data.total;
        this.pageSize = res.data.data.pageSize;

        hideLoading();

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        if (res.data.code == 0) {
          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);

    const optMoney = {
      url: reqUrl.getCashoutReceivedSum,
      method: "POST",
      params: JSON.stringify({ cashTime: this.selectDate }),
      resFunc: (res) => {
        this.AllMoney = res.data.data;

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optMoney);

    const optAuto = {
      url: reqUrl.getCashOutOnOff,
      method: "POST",
      params: JSON.stringify({}),
      resFunc: (res) => {
        this.isAuto = res.data.data;

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optAuto);
  },

  methods: {
    // 查询
    searchList() {
      if (this.phone || this.selectDate) {
        showLoading();
        const opt = {
          url: reqUrl.getWalletList,
          method: "POST",
          params: JSON.stringify({
            cashTime: this.selectDate,
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            sign: "",
            status: "",
            token: "",
          }),
          resFunc: (res) => {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            hideLoading();

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            if (res.data.code == 0) {
              res.data.data.list.map((item) => {
                this.changeItem(item);
              });
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);

        const optMoney = {
          url: reqUrl.getCashoutReceivedSum,
          method: "POST",
          params: JSON.stringify({ cashTime: this.selectDate }),
          resFunc: (res) => {
            this.AllMoney = res.data.data;

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(optMoney);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    // 开启自动提现
    handleAuto() {
      let flag = this.isAuto;
      this.isAuto = !this.isAuto; //保持switch点击前的状态

      this.$confirm("您确定要进行此操作吗，如确定，请继续", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const optAuto = {
            url: reqUrl.setCashOutOnOff,
            method: "POST",
            params: JSON.stringify({
              onOff: !this.isAuto,
            }),
            resFunc: (res) => {
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
              }
              if (res.data.code == 0) {
                this.refresh();
                this.$message.success("操作成功!");
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(optAuto);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 提现审核（成功或失败）
    handleClick(row, index) {
      console.log(row, index);
      this.listId = row.id;
      this.msg.realName = row.realname;
      this.msg.phone = row.cashAccount;
      this.msg.money = row.convertRmb;
      this.msg.count = row.received;
      if (row.fromType == "USDT") {
        this.msg.show = true;
      } else {
        this.msg.show = false;
      }

      if (index == 1) {
        this.$confirm("您确定要同意该用户的提现请求?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const opt = {
              url: reqUrl.withdrawalUrl,
              method: "POST",
              params: JSON.stringify({
                id: this.listId,
                nonce: "",
                sign: "",
                status: index,
                token: "",
                auditor: localStorage.getItem("userName"),
              }),
              resFunc: (res) => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "提现成功!",
                  });
                  this.refresh();
                }
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                }
                const opt = {
                  url: reqUrl.getWalletList,
                  method: "POST",
                  params: JSON.stringify({
                    cashTime: this.selectDate,
                    nonce: "",
                    pageNum: 0,
                    pageSize: 0,
                    phone: this.phone,
                    sign: "",
                    status: "0",
                    token: "",
                  }),
                  resFunc: (res) => {
                    this.tableData = res.data.data.list;
                    this.pageLength = res.data.data.total;
                    this.pageSize = res.data.data.pageSize;

                    hideLoading();

                    if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }

                    if (res.data.code == 0) {
                      res.data.data.list.map((item) => {
                        this.changeItem(item);
                      });
                    }
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    console.log(err, "err1");
                    hideLoading();
                  },
                };
                request(opt);
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消审核",
            });
          });
      } else if (index == 2) {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "拒绝原因不得为空",
          inputValidator: (value) => {
            if (!value) {
              return "拒绝原因不得为空";
            }
          },
        })
          .then(({ value }) => {
            const opt = {
              url: reqUrl.withdrawalUrl,
              method: "POST",
              params: JSON.stringify({
                id: this.listId,
                nonce: "",
                sign: "",
                status: index,
                rejectReason: value,
                token: "",
                auditor: localStorage.getItem("userName"),
              }),
              resFunc: (res) => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "拒绝成功",
                  });
                  const opt = {
                    url: reqUrl.getWalletList,
                    method: "POST",
                    params: JSON.stringify({
                      cashTime: this.selectDate,
                      nonce: "",
                      pageNum: 0,
                      pageSize: 0,
                      phone: this.phone,
                      sign: "",
                      status: "0",
                      token: "",
                    }),
                    resFunc: (res) => {
                      this.tableData = res.data.data.list;

                      this.pageLength = res.data.data.total;
                      this.pageSize = res.data.data.pageSize;

                      hideLoading();

                      if (res.data.code == 0) {
                        res.data.data.list.map((item) => {
                          this.changeItem(item);
                        });
                      }
                      if (res.data.code != 0) {
                        this.$notify.error({
                          title: "错误",
                          message: res.data.message,
                        });
                        hideLoading();
                      }
                    },
                    errFunc: (err) => {
                      this.$notify.error({
                        title: "错误",
                        message: err,
                      });
                      console.log(err, "err1");
                      hideLoading();
                    },
                  };
                  request(opt);
                }
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消审核",
            });
          });
      } else if (index == 3) {
        this.dialogVisible = true;
        // const h = this.$createElement;
        // this.$msgbox({
        //   title: "人工通道",
        //   message: h("div", null, [
        //     h("span", null, "真实姓名: "),
        //     h("span", { style: "color: teal" }, row.realname),
        //     h("br"),
        //     h("br"),
        //     h("span", null, "提现账号: "),
        //     h("span", { style: "color: teal" }, row.cashAccount),
        //     h("br"),
        //     h("br"),
        //     h("span", null, "提现到账金额: "),
        //     h("span", { style: "color: teal" }, row.received),
        //     h("br"),
        //     h("br"),
        //     h("button", null, "复制"),
        //   ]),
        //   showCancelButton: true,
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   beforeClose: (action, instance, done) => {
        //     if (action === "confirm") {
        //       instance.confirmButtonLoading = true;
        //       instance.confirmButtonText = "执行中...";
        //       setTimeout(() => {
        //         done();
        //         setTimeout(() => {
        //           instance.confirmButtonLoading = false;
        //         }, 300);
        //       }, 2000);
        //     } else {
        //       done();
        //     }
        //   },
        // })
        //   .then(() => {
        //     const opt = {
        //       url: reqUrl.cashOutNotAutoResult,
        //       method: "POST",
        //       params: JSON.stringify({
        //         id: this.listId,
        //         status: 1,
        //         auditor: localStorage.getItem("userName"),
        //       }),
        //       resFunc: (res) => {
        //         this.$message({
        //           type: "success",
        //           message: "提现成功!",
        //         });

        //         const opt = {
        //           url: reqUrl.getWalletList,
        //           method: "POST",
        //           params: JSON.stringify({
        //             cashTime: this.selectDate,
        //             nonce: "",
        //             pageNum: 0,
        //             pageSize: 0,
        //             phone: this.phone,
        //             sign: "",
        //             status: "0",
        //             token: "",
        //           }),
        //           resFunc: (res) => {
        //             this.tableData = res.data.data.list;
        //             this.pageLength = res.data.data.total;
        //             this.pageSize = res.data.data.pageSize;

        //             hideLoading();

        //             if (res.data.code != 0) {
        //               this.$notify.error({
        //                 title: "错误",
        //                 message: res.data.message,
        //               });
        //               hideLoading();
        //             }

        //             res.data.data.list.map((item) => {
        //               this.changeItem(item);
        //             });
        //           },
        //           errFunc: (err) => {
        //             this.$notify.error({
        //               title: "错误",
        //               message: err,
        //             });
        //             console.log(err, "err1");
        //             hideLoading();
        //           },
        //         };
        //         request(opt);
        //       },
        //       errFunc: (err) => {
        //         this.$notify.error({
        //           title: "错误",
        //           message: err,
        //         });
        //         console.log(err, "err1");
        //       },
        //     };
        //     request(opt);
        //   })
        //   .catch(() => {
        //     this.$message({
        //       type: "info",
        //       message: "已取消审核",
        //     });
        //   });
      }
    },

    handleClickTrue() {
      this.$prompt("请输入txid：", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.+$/,
        inputErrorMessage: "请输入Txid",
      })
        .then(({ value }) => {
          const opt = {
            url: reqUrl.cashOutNotAutoResult,
            method: "POST",
            params: JSON.stringify({
              id: this.listId,
              status: 1,
              auditor: localStorage.getItem("userName"),
              rejectReason: value,
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "提现成功!",
                });
                this.dialogVisible = false;
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }

              const opt = {
                url: reqUrl.getWalletList,
                method: "POST",
                params: JSON.stringify({
                  cashTime: this.selectDate,
                  nonce: "",
                  pageNum: 0,
                  pageSize: 0,
                  phone: this.phone,
                  sign: "",
                  status: "0",
                  token: "",
                }),
                resFunc: (res) => {
                  this.pageLength = res.data.data.total;
                  this.pageSize = res.data.data.pageSize;

                  hideLoading();

                  if (res.data.code != 0) {
                    this.$notify.error({
                      title: "错误",
                      message: res.data.message,
                    });
                    hideLoading();
                  }

                  if (res.data.code == 0) {
                    this.tableData = res.data.data.list;
                    res.data.data.list.map((item) => {
                      this.changeItem(item);
                    });
                  }
                },
                errFunc: (err) => {
                  this.$notify.error({
                    title: "错误",
                    message: err,
                  });
                  console.log(err, "err1");
                  hideLoading();
                },
              };
              request(opt);
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    onCopy() {
      this.$message("复制成功");
    },

    onError() {
      this.$message("复制失败");
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getWalletList,
        method: "POST",
        params: JSON.stringify({
          cashTime: this.selectDate,
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          sign: "",
          status: "",
          token: "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          this.currentPage = data.pageIndex;

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }

          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    // 重置
    reset() {
      if (this.phone || this.selectDate) {
        this.phone = "";
        this.selectDate = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 字段转换文字
    changeItem(item) {
      if (item.fromType == 1) {
        item.fromType = "余额账户";
      } else if (item.fromType == 2) {
        item.fromType = "CPLE积分账户";
      } else if (item.fromType == 3) {
        item.fromType = "支付宝账户";
      } else if (item.fromType == 4) {
        item.fromType = "微信账户";
      } else if (item.fromType == 5) {
        item.fromType = "京东账户";
      } else if (item.fromType == 6) {
        item.fromType = "转出额度";
      } else if (item.fromType == 7) {
        item.fromType = "USDT";
      } else if (item.fromType == 8) {
        item.fromType = "链上CPLE TOKEN";
      } else if (item.fromType == 9) {
        item.fromType = "数字人民币";
      } else if (item.fromType == 10) {
        item.fromType = "95%余额+5%cple";
      }

      if (item.cashMethod == 1) {
        item.cashMethod = "支付宝";
      } else if (item.cashMethod == 2) {
        item.cashMethod = "微信";
      } else if (item.cashMethod == 3) {
        item.cashMethod = "钱包地址";
      }

      if (item.status == 0) {
        item.status = "待提现";
      } else if (item.status == 1) {
        item.status = "提现成功";
      } else if (item.status == 2) {
        item.status = "提现失败";
      } else if (item.status == 3) {
        item.status = "提现中";
      }

      item.cashTime = format_time_date(item.cashTime);
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>
